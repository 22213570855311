import React, { Fragment } from 'react'
import styled from 'react-emotion'
import Facts from '../../components/Facts'
import Team from '../../components/Team'
import Testimonial from '../../components/Testimonial'
import { Four, One, Row, RowGroup, Three, Two } from '../../components/Grid'
import OtherProjects from '../../components/OtherProjects'
import ProjectsContext from '../../components/ProjectsContext'
import Image from '../../components/Image'
import Caption from '../../components/Caption'
import SEO from '../../components/SEO'
import { ProjectName } from '../../components/Style'
import Link from '../../components/Link'
import ContactForm from '../../components/ContactForm'
import Layout from '../../components/Layout'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/projects",
      "name": "Projects"
    }
  },{
    "@type": "ListItem",
    "position": 2,
    "item": {
      "@id": "https://500tech.com/projects/proggio",
      "name": "Proggio"
    }
  }]
}`

export default () => {
  return (
    <Layout>
    <ProjectsContext.Consumer clientName="Proggio">
      {({ allProjects, currentProject }) => (
        <Fragment>
          <SEO
            path="/projects/proggio"
            title="Featured React project for Proggio by 500Tech"
            description="We’ve built a React application in the project management industry for Proggio."
            ld={breadcrumbs}
          />

          <ProjectName>{currentProject.projectName}</ProjectName>
          <Facts {...currentProject} />

          <Row>
            <Three>
              <p>Proggio develops a new approach to project management.</p>

              <p>
                We've built Proggio's first project management application from
                ground up to deployment. It introduces project plan
                visualization and team collaboration, and improves planning,
                analyzing, tracking and controlling projects using their unique
                principles, guidelines, and processes.
              </p>

              <p>
                In June 2017, we have passed the knowledge and further
                development to Proggio's in-house engineers.
              </p>

              <p>
                <Link to="http://proggio.com/" white>
                  www.proggio.com
                </Link>
              </p>
            </Three>
          </Row>

          <Row>
            <Four>
              <Image path="projects/proggio/proggio-1.jpg" />
            </Four>
          </Row>

          <Team team={currentProject.team} />

          <RowGroup>
            <Row>
              <One>
                The infinite timeline that we implemented from scratch adapts
                its content and size according to zoom level.
              </One>
              <Three>
                <MediaContainer
                  style={{
                    paddingBottom: '38.16%',
                    position: 'relative',
                  }}>
                  <video
                    src="/assets/images/projects/proggio/proggio-timeline.mp4"
                    width="100%"
                    height="100%"
                    autoPlay
                    loop
                    muted
                    style={{ position: 'absolute' }}
                  />
                </MediaContainer>
              </Three>
            </Row>

            <Row>
              <Two>
                <MediaContainer
                  style={{
                    paddingBottom: '76.17%',
                    position: 'relative',
                  }}>
                  <video
                    src="/assets/images/projects/proggio/proggio-reorder.mp4"
                    width="100%"
                    height="100%"
                    autoPlay
                    loop
                    muted
                    style={{ position: 'absolute' }}
                  />
                </MediaContainer>
                <Caption>
                  Teams and areas in the sidebar can be reordered via drag and
                  drop
                </Caption>
              </Two>
              <Two>
                <MediaContainer
                  style={{
                    paddingBottom: '76.17%',
                    position: 'relative',
                  }}>
                  <video
                    src="/assets/images/projects/proggio/proggio-reveal.mp4"
                    width="100%"
                    height="100%"
                    autoPlay
                    loop
                    muted
                    style={{ position: 'absolute' }}
                  />
                </MediaContainer>
                <Caption>
                  Resizing the sidebar also reveals more options
                </Caption>
              </Two>
            </Row>
          </RowGroup>

          <RowGroup>
            <Row>
              <Three>
                <p>
                  All the activities on the timeline are movable and editable.
                  If a dragged activity has another activity after it, or links
                  to another activity from a different team, all the activities
                  will move together:
                </p>
              </Three>
            </Row>

            <Row>
              <Three>
                <MediaContainer
                  style={{
                    paddingBottom: '38.16%',
                    position: 'relative',
                  }}>
                  <video
                    src="/assets/images/projects/proggio/proggio-move.mp4"
                    width="100%"
                    height="100%"
                    autoPlay
                    loop
                    muted
                    style={{ position: 'absolute' }}
                  />
                </MediaContainer>
                <Caption>
                  The application always saves your state so you can undo and
                  redo your actions and don’t mess stuff accidentally
                </Caption>
              </Three>
              <One>
                One activity can push multiple others, so recursively figure out
                what activities to push. To make it efficient, we used
                Dijkstra’s algorithm to find relevant nodes in the timeline.
              </One>
            </Row>
          </RowGroup>

          <RowGroup>
            <Row>
              <Three>
                <p>
                  Since manipulating hundreds of DOM elements in real-time would
                  be very slow, we decided to use SVG elements.
                </p>
              </Three>
            </Row>

            <Row>
              <Two>
                <Image innerShadow path="projects/proggio/proggio-panel.jpg" />
                <Caption>
                  Entity properties can be edited in a properties panel
                </Caption>
              </Two>
              <Two>
                <Image innerShadow path="projects/proggio/proggio-menu.jpg" />
              </Two>
            </Row>
          </RowGroup>

          <RowGroup>
            <Row>
              <Three>
                <p>
                  With real-time collaboration through WebSockets users can
                  simultaneously edit timelines, and communicate with each other
                  through built-in messaging system.
                </p>
              </Three>
            </Row>
          </RowGroup>

          <Testimonial />

          <OtherProjects currentProject={currentProject} />
          <ContactForm />
        </Fragment>
      )}
    </ProjectsContext.Consumer>
    </Layout>
  )
}

const MediaContainer = styled.div`
  background-color: #333;
  background-size: cover;
  position: relative;
`
